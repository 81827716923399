import React from 'react'

type Props = {}

const Fb = (props: Props) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			className="stroke-current fill-transparent"
			width="35"
			height="35"
			viewBox="0 0 24 24"
			strokeWidth="1.5"
		>
			<path d="M7 10v4h3v7h4v-7h3l1 -4h-4v-2a1 1 0 0 1 1 -1h3v-4h-3a5 5 0 0 0 -5 5v2h-3" />
		</svg>
	)
}

export default Fb
